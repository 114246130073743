// @ts-expect-error no types available
import * as zephrBrowser from "@zephr/browser";
import { PAYWALL_OPENED_EVENT } from "./paywallcontrol";

export function runZephr(config: any) {
  return zephrBrowser.run(config);
}

export const ZEPHR_DECISIONS_FINISHED_EVENT = "zephr.browserDecisionsFinished";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host { display: block; position: relative; }

    :host .loading-container {
      align-items: center;
      background-color: var(--color-bg-subtle);
      justify-content: center;
      display: flex;
      inset: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    :host([disabled]) .loading-container {
      display: none !important;
    }

    .paid-barrier--loading {
      opacity: 0;
    }

    .spinner {
      width: 40px;
      height: 40px;
      border: 4px solid var(--color-border-default);
      border-top: 4px solid var(--color-border-highlight);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  </style>
  <div class="loading-container">
    <div class="spinner"></div>
  </div>
  <slot></slot>
`;

export class Paywall extends HTMLElement {
  static #zephrInitialized = false;
  static #zephrInitPromise: Promise<void> | null = null;

  readonly #boundHandleZephrDecisionFinished =
    this.#handleZephrDecisionFinished.bind(this);

  readonly #boundRunZephr = () => this.runZephr();

  get baseUrl(): string | undefined {
    return this.getAttribute("baseurl") ?? undefined;
  }

  get contentId(): string {
    return this.getAttribute("contentid") ?? "";
  }

  get contentUrl(): string | undefined {
    const contentUrl = this.getAttribute("contenturl") ?? "";

    if (!contentUrl) {
      return "";
    }

    return this.#rewriteUrl(contentUrl);
  }

  get headline(): string {
    return this.getAttribute("headline") ?? "";
  }

  get type(): string {
    return this.getAttribute("type") ?? "";
  }

  get subtype(): string {
    return this.getAttribute("subtype") ?? "";
  }

  get isDisabled(): boolean {
    return this.hasAttribute("disabled");
  }

  get isLoggedIn(): boolean {
    return this.getAttribute("loggedin") === "true";
  }

  get isDialog(): boolean {
    return this.getAttribute("dialog") === "true";
  }

  get isDebugMode(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("zephr") === "false" || urlParams.get("zephr") === "0";
  }

  get trackingType(): string | undefined {
    return this.getAttribute("trackingtype") ?? undefined;
  }

  get envBaseUrl(): string {
    return this.getAttribute("envBaseUrl") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  #isHiddenByParent() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let element: HTMLElement | null = this;
    while (element) {
      const style = window.getComputedStyle(element);
      if (style.display === "none" || style.visibility === "hidden") {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  }

  #isSinglePaywallInstance() {
    return document.querySelectorAll("ws-paywall").length === 1;
  }

  connectedCallback() {
    this.#checkZephrOverride();
    document.addEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#boundHandleZephrDecisionFinished,
    );

    if (!(this.#isHiddenByParent() && this.#isSinglePaywallInstance())) {
      this.runZephr();
    } else {
      document.addEventListener(PAYWALL_OPENED_EVENT, this.#boundRunZephr, {
        once: true,
      });
    }
  }

  disconnectedCallback() {
    document.removeEventListener(
      ZEPHR_DECISIONS_FINISHED_EVENT,
      this.#boundHandleZephrDecisionFinished,
    );
    document.removeEventListener(PAYWALL_OPENED_EVENT, this.#boundRunZephr);
  }

  async #handleZephrJWT(): Promise<string | null> {
    if (!this.isLoggedIn) {
      return null;
    }

    let jwt: string | null = null;
    const value: string | null = localStorage.getItem("zephr-key");

    if (value !== null) {
      try {
        const jwtData: JWTResponseBody = JSON.parse(value);
        const expireTimestamp = Math.floor(
          new Date(jwtData.expire).getTime() / 1000,
        );
        const nowTimestamp = Math.floor(Date.now() / 1000);

        if (expireTimestamp < nowTimestamp) {
          localStorage.removeItem("zephr-key");

          const response = await this.#getZephrTokenFromApi();
          if (!response) {
            throw new Error("Error while fetching zephr token");
          }

          this.#saveTokenToLocalStorage(response);

          jwt = response.token;
        }

        jwt = jwtData.token;
      } catch {
        throw new Error("Error while parsing jwt object");
      }
    } else {
      const response = await this.#getZephrTokenFromApi();
      if (!response) {
        throw new Error("Error while fetching zephr token");
      }

      this.#saveTokenToLocalStorage(response);
      jwt = response.token;
    }

    return jwt;
  }

  #saveTokenToLocalStorage(tokenData: JWTResponseBody) {
    localStorage.setItem("zephr-key", JSON.stringify(tokenData));
  }

  async #getZephrTokenFromApi(): Promise<JWTResponseBody | null> {
    const url = this.envBaseUrl + "/p-api/plenigo/zephr-token";
    try {
      const response = await fetch(url);
      return await response.json();
    } catch {
      return null;
    }
  }

  async runZephr() {
    const jwt = await this.#handleZephrJWT();

    const loadingContainer = this.shadowRoot!.querySelector(
      ".loading-container",
    ) as HTMLElement;

    const paidBarrier = this.#accessPaidBarrier();

    const controller = new AbortController();

    try {
      if (!this.isDisabled && !Paywall.#zephrInitialized) {
        Paywall.#zephrInitialized = true;

        const zephrConfig = {
          cdnApi: this.baseUrl,
          customData: {
            contentId: this.contentId,
            contentUrl: this.contentUrl,
            loggedIn: this.isLoggedIn,
          },
          jwt: jwt ? jwt : null,
          debug: this.isDebugMode,
          fetcher: (url: string, options: RequestInit) => {
            return fetch(url, {
              ...options,
              signal: controller.signal,
            }).then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }

              return response.json().then((data) => {
                return new Response(JSON.stringify(data), {
                  status: response.status,
                  statusText: response.statusText,
                  headers: {
                    ...Object.fromEntries(response.headers.entries()),
                    "Content-Type": "application/json",
                  },
                });
              });
            });
          },
        };

        Paywall.#zephrInitPromise = Promise.race([
          runZephr(zephrConfig),
          new Promise<void>((_, reject) =>
            setTimeout(() => {
              controller.abort();
              reject(new Error("timeout"));
            }, 2000),
          ),
        ]);
      }

      await Paywall.#zephrInitPromise;
    } catch (error) {
      console.error("Zephr error:", (error as Error).message);
      paidBarrier?.classList.remove("paid-barrier--loading");
    } finally {
      loadingContainer?.remove();
    }
  }

  #handleZephrDecisionFinished() {
    const paidBarrier = this.#accessPaidBarrier();

    if (!paidBarrier) {
      console.warn("No paid barrier found for Zephr decision tracking.");
      return;
    }

    console.log("Zephr paywall successfully replaced");

    const trackingParams = this.#buildTrackingParams();
    this.#appendTrackingParamsToLinks(paidBarrier, trackingParams);
  }

  #rewriteUrl(path: string): string | undefined {
    if (!path) return;

    const newUrl = new URL(path);
    const pathName = newUrl.pathname;
    const params = newUrl.search;
    const fullPath = params ? pathName + params : pathName;
    return new URL(fullPath, this.envBaseUrl).toString();
  }

  #buildTrackingParams(): string {
    const params = [
      this.contentId ? `contentId=${encodeURIComponent(this.contentId)}` : "",
      this.contentUrl
        ? `contentUrl=${encodeURIComponent(this.contentUrl)}`
        : "",
    ];

    if (this.contentUrl) {
      try {
        const url = new URL(this.contentUrl);
        url.searchParams.set("cc_bust", Date.now().toString());
        params.push(`wdycf=${encodeURIComponent(url.toString())}`);
      } catch (error) {
        console.warn((error as Error).message);
      }
    }

    return params.filter(Boolean).join("&");
  }

  #appendTrackingParamsToLinks(container: HTMLElement, trackingParams: string) {
    const links = container.querySelectorAll<HTMLAnchorElement | HTMLElement>(
      "a, ws-link",
    );

    links.forEach((link) => {
      const href = link.getAttribute("href");
      if (
        !href ||
        href.includes("contentId") ||
        href.includes("contentUrl") ||
        href.includes("wdycf")
      ) {
        return;
      }

      const newHref = href.includes("?")
        ? `${href}&${trackingParams}`
        : `${href}?${trackingParams}`;

      link.setAttribute("href", newHref);
    });
  }

  #checkZephrOverride() {
    if (this.isDebugMode) {
      const paidBarrier = this.#accessPaidBarrier();

      this.setAttribute("disabled", "");
      paidBarrier?.classList.remove("paid-barrier--loading");
    }
  }

  #accessPaidBarrier() {
    return (this.shadowRoot!.querySelector(".paid-barrier") ||
      this.querySelector(".paid-barrier")) as HTMLElement;
  }
}

if (!customElements.get("ws-paywall")) {
  customElements.define("ws-paywall", Paywall);
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElementTagNameMap {
    "ws-paywall": Paywall;
  }

  type JWTResponseBody = {
    status: string;
    expire: Date;
    token: string;
  };
}
